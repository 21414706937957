<template>
  <v-container>
    <h1 class="display-2">The River Guru</h1>

    <p class="pt-10">
      This website collects river and weather information from different sources
      and presents it in one convenient place for fishermen and anyone
      interested in the conditions of their local rivers around Ireland.
    </p>

    <h3 class="display-1">Data Feeds</h3>

    <p class="pt-5 pb-5">
      Hydrometric information is collected from the ESB -
      <a href="http://esbhydro.ie/">here</a>
    </p>
    <p class="pt-0">
      Weather information in is collected from Met Éireann Weather Forecast
      <a href="https://data.gov.ie/dataset/met-eireann-weather-forecast-api"
        >API</a
      >
    </p>
    <p class="pt-5 pb-5">
      Water levels are collected from the Office of Public Works public
      <a
        href="https://data.gov.ie/dataset/opw-hydrometric-network-real-time-water-level-data"
      >
        API
      </a>
    </p>

    <h3 class="display-1">Disclaimer</h3>
    <p class="pt-5">
      This site has user generated content. No guarantee can be made for the
      accuracy of completeness of content. Your use of this content is AT YOUR
      OWN RISK. River conditions (Such as Gauge Height, Flow Rate,
      Precipitation, and Temperature) can change without warning, and may be
      incorrect.
    </p>
    <h3 class="display-1">Want To See More Rivers Or More Data ?</h3>
    <p class="pt-5">
      If you'd like other irish rivers listed on this site or other data please
      contact me
      <a href="https://form.jotform.com/210906718558362"> Contact Us</a>
    </p>

    <p class="pt-5">
      tight lines folks ! - Aidan
    </p>
  </v-container>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
  };
</script>
<style></style>
